import { SxStyleProp } from 'theme-ui';

export const Half: SxStyleProp = {
  width: ['100%','100%','50%'],
};

export const Stack: SxStyleProp = {
  alignContent: 'center',
  p: '0',
  display: ['block', 'flex'],
};

export const Content: SxStyleProp = {
  maxWidth: '420px',
  p: ['48px 12px', '24px 12px', '48px 12px'],
  m: 'auto',
  alignSelf: 'center',
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto !impor',
  minHeight: '1px',
  mb: '0',
  verticalAlign: 'bottom',
};

export const Title: SxStyleProp = {
  textTransform: 'uppercase',
  height: 'auto',
  textAlign: 'center',
  fontSize:[16, 16, 26],
  lineHeight: ['36px', '36px', '52px'],
  mb: '12px',
};

export const Description: SxStyleProp = {
  maxWidth: '480px',
  height: 'auto',
  textAlign: 'center',
  alignSelf: 'center',
  fontSize: [10, 10, 14],
  lineHeight: ['28px', '28px', '36px'],
};

export const ImageBox: SxStyleProp = {
  minHeight: '1px',
  flex: '1 1 auto',
  display: 'block',
};
