import { SxStyleProp } from 'theme-ui';

export const Banner: SxStyleProp = {
  width: '100%',
  backgroundColor: 'primary',
  m: 'auto',
  flexDirection: 'row',
  justifyContent: 'space-around',
};

export const MascotColumn: SxStyleProp = {
  width: 'calc(100% / 3)',
  alignSelf: 'center',
  m: ['initial', 'auto'],
  alignItems: ['normal','flex-end'],
};

export const Hearts: SxStyleProp = {
  maxWidth: ['112px','240px','340px'],
  m: 'auto',
};

export const ImageBox: SxStyleProp = {
  p: '0 16px',
  width: '100%',
};

export const Image: SxStyleProp = {
  width: '100%',
  m: '0',
  display: 'block',
  verticalAlign: 'middle',
  height: 'auto',
  minHeight: '1px',
};

export const MessageColumn: SxStyleProp = {
  width: 'calc(100% / 3 * 2 )',
  m: 'auto',
  flexDirection: 'column',
};

export const Message: SxStyleProp = {
  width: '100%',
  color: 'white',
  m: ['16px 0', 'auto'],
  p: '0 16px',
  fontWeight: 'bold',
  fontSize: [12, 18, 26],
  lineHeight: ['24px', '42px', '56px'],
  fontFamily: 'primary',
};
