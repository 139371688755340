import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, sortBy as _sortBy } from 'lodash';

import { ILineObject } from '@lce/intl-types/src/ILineObject';
import localeFilter, { localeFilterList } from '@lce/intl-util/src/localeFilter';
import removeLine from '@lce/intl-util/src/removeLine';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';

interface IDatoUseAboutUs {
  aboutUsBanner: IDatoNodeAboutUsBanner;
  aboutUsPanel: IDatoNodeAboutUsPanel;
}

interface IDatoNodeAboutUsBanner {
  nodes: IDatoAboutUsBanner[];
}

interface IDatoNodeAboutUsPanel {
  nodes: IDatoAboutUsPanel[];
}

interface IDatoAboutUsBanner {
  locale: string;
  bannerImage: IDatoImage;
  bannerText: string;
}

interface IDatoAboutUsPanel {
  locale: string;
  position: number;
  panelImage: IDatoImage;
  title: ILineObject[];
  description: string;
}

// eslint-disable-next-line max-lines-per-function
const useAboutUs = (locale: string) => {
  const data: IDatoUseAboutUs = useStaticQuery(graphql`
    query UseAboutUsQuery {
      aboutUsBanner: allDatoCmsAboutUsBanner {
        nodes {
          locale
          bannerImage {
            url
          }
          bannerText
        }
      }
      aboutUsPanel: allDatoCmsAboutUsPanel(sort: { fields: title___line }) {
        nodes {
          locale
          position
          panelImage {
            url
          }
          title {
            line
          }
          description
        }
      }
    }
  `);

  const filteredAboutUsBanner = localeFilter<IDatoAboutUsBanner>(
    data.aboutUsBanner && data.aboutUsBanner.nodes,
    locale,
  );

  const filteredAboutUsPanelList = localeFilterList<IDatoAboutUsPanel>(data.aboutUsPanel && data.aboutUsPanel.nodes, locale);
  const cleanedAboutPanelList = _map(filteredAboutUsPanelList, aboutUsPanel => {
    const description = aboutUsPanel.description;
    return ({
      ...aboutUsPanel,
      panelImage: aboutUsPanel.panelImage.url,
      title: removeLine(aboutUsPanel.title),
      description,
    });
  });

  const sortedAboutUsPanelList = _sortBy(cleanedAboutPanelList, (panel) => panel.position);

  return {
    aboutUsBanner: {
      ...filteredAboutUsBanner,
      bannerText: filteredAboutUsBanner.bannerText,
      bannerImage: filteredAboutUsBanner.bannerImage.url,
    },
    aboutUsPanel: {
      ...sortedAboutUsPanelList,
    },
  };
};

export default useAboutUs;

