import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';
import Text from '@lce/slice_v2/Elements/Text';
import Flex from '@lce/slice_v2/Layout/Flex';

import ComponentListRenderer from '../../../Modules/ComponentListRenderer';
import Container from '../../../Layout/Container';
import * as Styled from './AboutUsStack.style';

export interface IAboutUsStackProps {
  title: string[];
  description: string | string[];
  image?: string;
  isReversed?: boolean;
}

const AboutUsStack: React.FC<IAboutUsStackProps> = ({ description, image, isReversed, title }) => (
  <Container sx={{
    ...Styled.Stack,
    backgroundColor: isReversed ? 'primary' : '#eeeff1',
    flexDirection: ['column', isReversed ? 'row-reverse' : 'row'],
  }}>
    <Flex sx={Styled.Half}>
      {image && <Box sx={Styled.ImageBox}>
        <Image src={image} sx={Styled.Image} />
      </Box>
      }
    </Flex>
    <Flex sx={Styled.Half}>
      <Box sx={Styled.Content}>
        <Text as='h2'
          sx={{
            ...Styled.Title,
            color: isReversed ? 'white' : 'black',
          }}>
          <ComponentListRenderer listText={title} />
        </Text>
        <Text sx={{
          ...Styled.Description,
          color: isReversed ? 'white' : 'black',
        }}>
          {description}
        </Text>
      </Box>
    </Flex>
  </Container>
);

export default AboutUsStack;
